<!--
 * @Description: 素材、图片空间
 * @Author: 琢磨先生
 * @Date: 2022-05-31 10:46:23
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-07-05 05:50:38
-->
<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true" class="no-margin-bottom">
      <el-form-item label="关键字">
        <el-input
          v-model="query.q"
          placeholder="名称、手机号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div></div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-row class="tools">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item @click="onBreadCrumb()">
          <el-link :underline="false">文件库</el-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item
          v-for="item in breadcrumbList"
          :key="item.id"
          @click="onBreadCrumb(item)"
        >
          <el-link :underline="false">{{ item.name }}</el-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="groups">
        <el-button size="small" @click="onEdit()" round icon="plus"
          >创建文件夹</el-button
        >

        <el-popconfirm title="去定要删除选择的数据？" @confirm="deleteBatch">
          <template #reference>
            <!-- <el-button type="text" size="small" class="text-danger"
              >删除</el-button
            > -->
            <el-button
              size="small"
              type="danger"
              round
              icon="Delete"
              :disabled="selectionList.length == 0"
              >批量删除</el-button
            >
          </template>
        </el-popconfirm>
        <psd-upload :folder="currentFolder" @close="uploadClose"></psd-upload>
      </div>
    </el-row>

    <el-table
      :data="tableData.data"
      @sort-change="onSort"  
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="名称">
        <template #default="scope">
          <div class="file-name">
            <span class="thumb">
              <el-icon size="50px" v-if="scope.row.psd_type == 0"
                ><FolderOpened
              /></el-icon>

              <el-image
                v-if="scope.row.psd_type == 1"
                style="width: 50px; height: 50px"
                :src="scope.row.file_url"
                :preview-src-list="srcList"
                :initial-index="
                  srcList.findIndex((x) => x == scope.row.file_url)
                "
                fit="cover"
              />
            </span>
            <span>{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="size_text" label="大小" width="100">
      </el-table-column>
      <el-table-column label="尺寸" width="100">
        <template #default="scope">
          <span v-if="scope.row.psd_type == 1"
            >{{ scope.row.width }}x{{ scope.row.height }}</span
          ><span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" prop="create_at" width="180">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button
            type="primary" link
            size="small"
            @click.stop="onEdit(scope.row)"
            v-if="scope.row.psd_type == 0"
            >修改</el-button
          >
          <el-button type="primary" link size="small" @click="rowClick(scope.row)" v-if="scope.row.psd_type == 0">打开</el-button>
          <el-button
            type="primary" link
            size="small"
            class="copy"
            @click.stop="onCopy($event, scope.row)"
            v-if="scope.row.psd_type == 1"
            >复制链接</el-button
          >
          <el-popconfirm title="去定要删除？" @confirm.stop="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" 
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    >
    </el-pagination>
  </el-card>

  <el-dialog
    title="创建文件夹"
    v-model="dialogVisible"
    width="500px"
    :destroy-on-close="true"
  >
    <el-form ref="form" :model="form">
      <el-form-item label="" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入文件夹名称"
          size="large"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import PsdUpload from "./psd_upload.vue";
import Clipboard from "clipboard";
export default {
  components: {
    PsdUpload,
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      form: {},

      tableData: {
        counts: 0,
      },
      //当前展开的文件夹
      currentFolder: null,
      //路径
      breadcrumbList: [],
      //预览的地址集合
      srcList: [],
      //选择的数据
      selectionList: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.current = null;
      this.loading = true;
      this.$http.post("seller/v1/psd/file", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
          this.tableData.data.forEach((item) => {
            if (item.psd_type == 1) {
              this.srcList.push(item.file_url);
            }
          });
        }
        this.loading = false;
      });
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 修改文件夹
     */
    onEdit(item) {
      this.form = Object.assign({}, item);
      this.dialogVisible = true;
    },

    /**
     * 保存文件夹
     */
    onSubmit() {
      if (this.currentFolder) {
        this.form.father_id = this.currentFolder.id;
      }
      this.$http.post("seller/v1/psd/folder/edit", this.form).then((res) => {
        if (res.code == 0) {
          this.loadData();
          this.dialogVisible = false;
        }
      });
    },
    /**
     * 删除文件
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("seller/v1/psd/file/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
    /**
     * BreadCrumb 点击
     */
    onBreadCrumb(item) {
      if (item) {
        var i = this.breadcrumbList.findIndex((o) => o.id == item.id);
        this.breadcrumbList.splice(i + 1, this.breadcrumbList.length - i);
      } else {
        this.query.father_id = "";
        this.breadcrumbList = [];
      }
      this.loadData();
    },
    /**
     * 列表点击事件
     */
    rowClick(row) {
      if (row.psd_type == 0) {
        this.breadcrumbList.push(row);
        this.currentFolder = row;
        this.query.father_id = row.id;
        this.loadData();
      }
    },
    /**
     * 上传关闭
     */
    uploadClose() {
      this.loadData();
    },
    /**
     * 选择更改
     * @param {*} rows
     */
    selectionChange(rows) {
      this.selectionList = rows;
    },
    /**
     * 批量删除选择的数据
     */
    deleteBatch() {
      if (this.selectionList.length > 0) {
        var ids = this.selectionList.map((x) => x.id);
        this.$http.post("seller/v1/psd/file/del-batch", ids).then((res) => {
          if (res.code == 0) {
            this.selectionList = [];
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
      }
    },
    /**
     * 复制链接
     */
    onCopy(e, row) {
      var clipboard = new Clipboard(".copy", {
        text: () => {
          return row.file_url;
        },
      });
      clipboard.on("success", () => {
        console.log("复制成功");
        clipboard.destroy();
      });
      clipboard.onClick(e);
    },
  },
};
</script>

<style  scoped>
.tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.tools .groups {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tools .el-breadcrumb {
  padding-left: 0;
  padding-top: 0;
  font-size: 16px;
  line-height: 16px;
}
.tools .el-breadcrumb .el-link {
  font-size: 16px;
  line-height: 16px;
}
.el-table .file-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.el-table .file-name .thumb {
  margin-right: 20px;
}
.el-table .file-name .thumb .el-icon {
  color: var(--el-color-primary);
}
</style>